import React, { useState } from 'react'
import * as Styled from './OneTrustPrivacyPolicy.styles'

const OneTrustPrivacyPolicy = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  if (typeof document !== 'undefined') {
    const appendScript = (script) => document.body.appendChild(script)

    const script = document.createElement('script')
    // unique per site
    script.src =
      'https://appds8093.blob.core.windows.net/privacy-notice-scripts/otnotice-1.0.min.js'
    script.type = 'text/javascript'
    script.charSet = 'UTF-8'
    script.id = 'otprivacy-notice-script'
    script.async = true
    script.innerHTML = `settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"`
    script.onload = () => setIsLoaded(true)

    const loadNotices = document.createElement('script')
    loadNotices.type = 'text/javascript'
    loadNotices.charSet = 'UTF-8'
    // unique per site
    loadNotices.innerHTML = `OneTrust.NoticeApi.Initialized.then(function() {
    OneTrust.NoticeApi.LoadNotices([
      'https://appds8093.blob.core.windows.net/11ccab12-653b-4e89-b206-f783168adc9b/privacy-notices/0a15486c-cea4-4578-a004-290d9691deb6.json',
    ])
  })`

    appendScript(script)

    if (isLoaded) {
      appendScript(loadNotices)
    }
  }

  return (
    <Styled.OneTrustPrivacyPolicy>
      {!isLoaded && <h2 className="loading">Loading Privacy Policy...</h2>}
      {/* unique per site */}
      <div id="otnotice-0a15486c-cea4-4578-a004-290d9691deb6" />
    </Styled.OneTrustPrivacyPolicy>
  )
}

export default OneTrustPrivacyPolicy
